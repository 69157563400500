/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/starkey/'], ['en', 'https://www.hear.com/hearing-aids/brands/starkey/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/starkey/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/starkey/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "starkey-hear-better-for-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-hear-better-for-life",
    "aria-label": "starkey hear better for life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey: Hear better for life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you’re diagnose with hearing loss, it’s easy to feel like you’re on your own. With ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "the right pair of hearing aids"), " like those from Starkey, however, you can take back your life. Starkey has been a world leader in ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "hearing aid technology"), " for over 50 years. At Starkey, your hearing is their top concern. Learn more about Starkey and the innovative technology behind their latest and greatest hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Founded in 1967 by William F. Austin, Starkey’s humble start began as a hearing aid repair store in Minnesota. After a few years, Austin bought a hearing aid ear mold company, known as Starkey Labs, and the rest was history. These days, Starkey is one of the United States’ leading producers of hearing aids, thanks to its easy-to-use technology and fantastic customer service."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In fact, the company saw a huge spike in sales after Ronald Reagan wore their hearing aids during his presidency. Since then, Austin himself has personally fitted five different U.S. Presidents, two popes, and two famous humanitarians (Nelson Mandela and Mother Theresa) with hearing aids. That’s a line-up to be proud of."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At Starkey, your hearing is at the forefront of their innovation and design as they look to empower people with hearing loss to take back control of their lives. With a Starkey hearing aid and all of its purpose-built technology, you can start to realize your hearing potential."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "take-control-of-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#take-control-of-your-hearing",
    "aria-label": "take control of your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Take Control of Your Hearing"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-muse.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the hearing aid industry, Starkey is well known for creating new devices with groundbreaking technology. But the company isn’t interested in new technology just for technology’s sake."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Instead, every new innovation and development that comes from Starkey is designed to help individuals by providing them with the tools to reach specific, measurable goals. By starting with the patient’s experience, Starkey has proven itself as an industry leader in making meaningful advances in hearing aid technology. Let’s take a look at what’s new in the world of Starkey hearing aids:")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "livio-ai",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#livio-ai",
    "aria-label": "livio ai permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Livio AI"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The world’s first hearing aid to combine impeccable sound quality with a brain and body health tracker, Livio AI is the newest in wearable tech. This hearing aid allows individuals to listen easily, even in particularly noisy or challenging environments thanks to its Hearing Reality technology."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With seamless compatibility with Starkey’s Thrive smartphone app, it’s easy to make adjustments on-the-go to the Livio AI hearing aid. This means that Livio AI is better prepared to improve your hearing experience, one sound at a time. Plus, with automatic wireless streaming and intelligent memory technology, the Livio AI is ready to provide personalized hearing support, wherever life takes you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-and-activity-tracking",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-and-activity-tracking",
    "aria-label": "hearing and activity tracking permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing and Activity Tracking"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the new age of wearable tech, the ability to track and record our activity is paramount. Thankfully, Starkey is up-to-date with the times, providing us with a range of hearing aids that make monitoring our brain and body health a breeze."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Starkey’s Thrive hearing app, you can easily connect your smartphone to your hearing aids, which provides instant and useful insight into your hearing and physical health. In addition to providing hearing aid users with the ability to remotely control their devices, the Thrive app provides daily step and activity tracking, heart rate information, and fall detection technology, making this one of the most advanced technological platforms out there."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "in-the-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-news",
    "aria-label": "in the news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In the News"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As one of the leading hearing aid companies in the world, Starkey has the ability to bring the gift of hearing to millions in need. It turns out, that’s exactly what the company does through the Starkey Hearing Foundation – a pioneer in hearing aid-based philanthropy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Throughout Starkey Hearing Foundation’s thirty year history, they’ve provided more than 1.5 million different hearing healthcare services to people around the world, changing lives one appointment at a time. In one of their newest initiatives, called Listen Carefully, Starkey hopes to protect the hearing of millions of vulnerable Americans that may be at risk for hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Current statistics show that nearly one in six American teens are at risk of developing hearing loss throughout their life, thanks to exposure to excessively loud noises. The Starkey Hearing Foundation is currently working with a number of public figures, such as Tony Hawk and Sammy Hagar to spread the word about the damaging effects of hearing loss to these vulnerable teens to protect their hearing for a lifetime to come. Way to go, Starkey!"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "starkey-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-hearing-aids",
    "aria-label": "starkey hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-halo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-halo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-halo",
    "aria-label": "starkey halo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Halo"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "We rely on technology everyday by texting friends and family with our mobile phones, streaming movies on our iPads, watching movies on our computers and televisions, and much more! We listen to conversations through ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth® earpieces"), " and search for restaurants by using voice recognition with our electronic devices. Hearing aids today have advanced technology to make your life more simple."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/halo/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-muse.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-muse",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-muse",
    "aria-label": "starkey muse permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Muse"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "For those who don’t know, Starkey Hearing Technologies is one of the biggest names in the world of hearing aids and other auditory technology. Based in the USA, Starkey has been trendsetters for decades in fields like miniaturization, wireless technology, comfortable in-canal hearing aids and programmable settings – and the Starkey Muse builds on their reputation."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/muse/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-livio-hearing-aid.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-livio",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-livio",
    "aria-label": "starkey livio permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Livio"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s innovation, but it’s not LIFE-CHANGING innovation. For people suffering from hearing loss, it’s finally time for a new product that makes hearing customizable and convenient. Starkey’s Livio hearing aid is a device that WILL enrich your life. If you like technology and messing around with settings on your smartphone, you are going to love the Livio."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/livio/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/starkey-picasso.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "starkey-picasso",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#starkey-picasso",
    "aria-label": "starkey picasso permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Starkey Picasso"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Losing your hearing is never a good thing, especially if you enjoy listening to music. Finally, there’s a solution for music lovers! It’s called Picasso by Starkey. Imagine being able to customize your musical experiences to your preferences! Don’t skip the concert or sell your piano just because you suffer from hearing loss."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey/picasso/",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
